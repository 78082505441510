import React from "react";
import {
  SEO,
  PageLayout,
  FadeReveal,
  MarkdownContent,
  Image,
} from "@bluefin/components";
import { Grid, Header } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class AboutPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout hero={false} subfooter={false} className={"about-page"}>
          <Grid
            className={"about-content-container"}
            stackable={true}
            textAlign={"center"}
          >
            <Grid.Column
              width={8}
              textAlign={"left"}
              verticalAlign={"middle"}
              className={"markdown-column"}
            >
              <Header
                as={"h1"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "content_section_header",
                  defaultValue: "About",
                })}
              />
              <FadeReveal duration={1250} triggerOnce={true}>
                <MarkdownContent
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "content_section_description",
                  })}
                />
              </FadeReveal>
            </Grid.Column>
            <Grid.Column width={8} className={"image-column"}>
              <FadeReveal duration={1250} triggerOnce={true} delay={450}>
                <Image
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Content.Image",
                    componentIdentifier: "content_section_image",
                    numToSelect: 1,
                  })}
                  background={false}
                  className={"image"}
                />
              </FadeReveal>
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            centered={true}
          >
            <Grid.Row>
              <Grid.Column width={16} textAlign={"center"}>
                <Header as={"h2"} className={""} content={"At Your Service"} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8} textAlign={"centete"}>
                <div className={"about-team-container"}>
                  <div width={16} className={"about-team-image"}>
                    <Image
                      size={"medium"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/7e82fa70-f6f7-4eae-b732-518b46089873/FB_IMG_1719440693559_gS2sxOR.jpg"
                      }
                    />
                  </div>
                  <div className={"about-team-content"}>
                    <h3>Jessica</h3>
                    <h5>Corporate General Manager</h5>
                    <p>jessicaC@lapomponnee.com</p>
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column width={8} textAlign={"centete"}>
                <div className={"about-team-container"}>
                  <div width={16} className={"about-team-image"}>
                    <Image
                      size={"medium"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/7e82fa70-f6f7-4eae-b732-518b46089873/lapomponnee-85_8cVCDcY.jpg"
                      }
                    />
                  </div>
                  <div className={"about-team-content"}>
                    <h3>Joslin (aka JoJo)</h3>
                    <h5>Assistant General Manager</h5>
                    <p>jojoiner@lapomponnee.com</p>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "About" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
    }
  }
`;
